import { render, staticRenderFns } from "./AdditionalTermsModal.vue?vue&type=template&id=3619ca17&scoped=true"
import script from "./AdditionalTermsModal.vue?vue&type=script&lang=js"
export * from "./AdditionalTermsModal.vue?vue&type=script&lang=js"
import style0 from "./AdditionalTermsModal.scss?vue&type=style&index=0&id=3619ca17&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3619ca17",
  null
  
)

export default component.exports