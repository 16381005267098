<template>
    <div class="download-panel">
        <div class="row row-cols-1 size-selector" v-if="content">
            <div role="radiogroup" v-if="content.attributes.content_type === 'image'">
                <div class="col" :class="{active: selectedSize === 'S'}"
                     v-if="content.relationships.size_info.s.size !== ''"
                     role="radio"
                     :aria-label="$t('accessibility.sSizeContent')"
                     :aria-checked="selectedSize === 'S' ? 'true' : 'false'"
                     @click="selectSize('S')"
                     @keyup.enter="selectSize('S')"
                     @keyup.space="selectSize('S')"
                     tabindex="0">
                    <div class="card">
                        <div class="card-body d-flex">
                            <div class="size-tip d-flex justify-content-center align-items-center" tabindex="0">
                                <h2>S</h2>
                                <p>{{ content.attributes.content_type === 'image' ? 'size' : 'HD' }}</p>
                            </div>
                            <div class="size-info" tabindex="0">
                                <div>
                                    <p>1280 × 720 pixel</p>
                                </div>
                                <p>{{ convertByteSize(parseInt(content.relationships.size_info.s.size)) }} / {{ content.attributes.codec }}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col" :class="{active: selectedSize === 'M'}"
                     v-if="content.relationships.size_info.m.size !== ''"
                     role="radio"
                     :aria-label="$t('accessibility.mSizeContent')"
                     :aria-checked="selectedSize === 'M' ? 'true' : 'false'"
                     @click="selectSize('M')"
                     @keyup.enter="selectSize('M')"
                     @keyup.space="selectSize('M')"
                     tabindex="0">
                    <div class="card">
                        <div class="card-body d-flex">
                            <div class="size-tip d-flex justify-content-center align-items-center" tabindex="0">
                                <h2>M</h2>
                                <p>{{ content.attributes.content_type === 'image' ? 'size' : 'Full HD' }}</p>
                            </div>
                            <div class="size-info" tabindex="0">
                                <div>
                                    <p>1920 × 1080 pixel</p>
                                </div>
                                <p>{{ convertByteSize(parseInt(content.relationships.size_info.m.size)) }} / {{ content.attributes.codec }}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col" :class="{active: selectedSize === 'L'}"
                     v-if="content.relationships.size_info.l.size !== ''"
                     role="radio"
                     :aria-label="$t('accessibility.lSizeContent')"
                     :aria-checked="selectedSize === 'L' ? 'true' : 'false'"
                     @click="selectSize('L')"
                     @keyup.enter="selectSize('L')"
                     @keyup.space="selectSize('L')"
                     tabindex="0">
                    <div class="card">
                        <div class="card-body d-flex">
                            <div class="size-tip d-flex justify-content-center align-items-center" tabindex="0">
                                <h2>L</h2>
                                <p>{{ content.attributes.content_type === 'image' ? 'size' : '4K' }}</p>
                            </div>
                            <div class="size-info" tabindex="0">
                                <p>{{ content.attributes.width }} × {{ content.attributes.height }} pixel</p>
                                <p>{{ convertByteSize(content.attributes.size) }} / {{ content.attributes.codec }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div role="radiogroup" v-else>
                <div class="col" :class="{active: selectedSize === 'S'}"
                     v-if="content.relationships.size_info.s.size !== ''"
                     role="radio"
                     :aria-label="$t('accessibility.sSizeContent')"
                     :aria-checked="selectedSize === 'S' ? 'true' : 'false'"
                     @click="selectSize('S')"
                     @keyup.enter="selectSize('S')"
                     @keyup.space="selectSize('S')"
                     tabindex="0">
                    <div class="card">
                        <div class="card-body d-flex">
                            <div class="size-tip d-flex justify-content-center align-items-center">
                                <div class="text-center" tabindex="0">
                                    <h2>S</h2>
                                    <p>HD</p>
                                </div>
                            </div>
                            <div class="size-info" tabindex="0">
                                <div>
                                    <p>1280 × 720 pixel</p>
                                </div>
                                <p>{{ convertByteSize(parseInt(content.relationships.size_info.s.size)) }} / {{ content.attributes.codec }}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col" :class="{active: selectedSize === 'M'}"
                     v-if="content.relationships.size_info.m.size !== ''"
                     role="radio"
                     :aria-label="$t('accessibility.mSizeContent')"
                     :aria-checked="selectedSize === 'M' ? 'true' : 'false'"
                     @click="selectSize('M')"
                     @keyup.enter="selectSize('M')"
                     @keyup.space="selectSize('M')"
                     tabindex="0">
                    <div class="card">
                        <div class="card-body d-flex">
                            <div class="size-tip d-flex justify-content-center align-items-center">
                                <div class="text-center" tabindex="0">
                                    <h2>M</h2>
                                    <p>Full HD</p>
                                </div>
                            </div>
                            <div class="size-info" tabindex="0">
                                <div>
                                    <p>1920 × 1080 pixel</p>
                                </div>
                                <p>{{ convertByteSize(parseInt(content.relationships.size_info.m.size)) }} / {{ content.attributes.codec }}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col" :class="{active: selectedSize === 'L'}"
                     v-if="content.relationships.size_info.l.size !== ''"
                     role="radio"
                     :aria-label="$t('accessibility.lSizeContent')"
                     :aria-checked="selectedSize === 'L' ? 'true' : 'false'"
                     @click="selectSize('L')"
                     @keyup.enter="selectSize('L')"
                     @keyup.space="selectSize('L')"
                     tabindex="0">
                    <div class="card">
                        <div class="card-body d-flex">
                            <div class="size-tip d-flex justify-content-center align-items-center">
                                <div class="text-center" tabindex="0">
                                    <h2>L</h2>
                                    <p>4K</p>
                                </div>
                            </div>
                            <div class="size-info" tabindex="0">
                                <p>{{ content.attributes.width }} × {{ content.attributes.height }} pixel</p>
                                <p>{{ convertByteSize(parseInt(content.relationships.size_info.l.size)) }} / {{ content.attributes.codec }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col" :class="{active: selectedText}"
                 v-if="isPc()"
                 role="checkbox"
                 :aria-label="$t('accessibility.textContent')"
                 :aria-checked="selectedText ? 'true' : 'false'"
                 @click="selectText()"
                 @keyup.enter="selectText()"
                 @keyup.space="selectText()"
                 tabindex="0">
                <div class="card">
                    <div class="card-body d-flex">
                        <div class="size-tip d-flex">
                            <div class="text-center" tabindex="0">
                                <h2>T</h2>
                                <p>text</p>
                            </div>
                        </div>
                        <div class="size-info d-flex align-items-center" tabindex="0">
                            <svg class="ico_text-data" xmlns="http://www.w3.org/2000/svg" width="35" height="39" viewBox="0 0 35 39">
                                <g transform="translate(-886 -871)"><g transform="translate(886 871)" fill="#fff" stroke="#707070" stroke-width="1"><rect width="35" height="39" rx="2" stroke="none"/>
                                    <rect x="0.5" y="0.5" width="34" height="38" rx="1.5" fill="none"/></g><line x2="22" transform="translate(892.5 878.5)" fill="none" stroke="#707070" stroke-width="1"/>
                                    <line x2="22" transform="translate(892.5 884.5)" fill="none" stroke="#707070" stroke-width="1"/>
                                    <line x2="22" transform="translate(892.5 890.5)" fill="none" stroke="#707070" stroke-width="1"/>
                                    <line x2="22" transform="translate(892.5 896.5)" fill="none" stroke="#707070" stroke-width="1"/>
                                    <line x2="22" transform="translate(892.5 902.5)" fill="none" stroke="#707070" stroke-width="1"/>
                                </g>
                            </svg>
                            <div class="ml-3">
                                <p class="card-title">{{ $t('downloads.textData') }}</p>
                                <p class="card-text">{{ $t('downloads.format') }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mt-2">
            <div class="col-12">
                <ul role="list">
                    <li tabindex="0" role="listitem">
                        {{ $t('downloads.chooseSize') }}
                    </li>
                    <li tabindex="0" v-if="isPc()" role="listitem">
                        {{ $t('downloads.canDownloadTextData') }}
                    </li>
                    <li tabindex="0" v-if="content.attributes.image_type === 'searchConditions.imageTypes.vr'" role="listitem">
                        {{ $t('downloads.vrTextData') }}
                    </li>
                </ul>
            </div>
        </div>
        <div class="row">
            <div class="col-12 text-center">
                <input type="checkbox"
                       tabindex="0"
                       class="checkbox"
                       role="checkbox"
                       id="checkbox"
                       aria-checked="false"
                       v-model="isAgree">
                <label for="checkbox" v-html="$t('downloads.agree')"></label>
            </div>
        </div>
        <div class="row mt-3" v-if="isLicenseType">
            <div class="col-12 mb-3">
                <ul role="list">
                    <li role="listitem">{{ $t('downloads.additionalTerms') }}</li>
                </ul>
            </div>
            <div class="col-12 text-center">
                <span role="button"
                      @click="$refs.additionalTermsModal.open()"
                      @keyup.space="$refs.additionalTermsModal.open()"
                      tabindex="0">
                    {{ $t('downloads.readAdditionalTerms') }}
                </span>
            </div>
        </div>
        <div class="row">
            <div class="text-center btn-download">
                <button class="btn btn-success btn-block"
                        tabindex="0"
                        :class="{disabled: !canDownload}"
                        @click="download">
                    <svg class="ico_download" xmlns="http://www.w3.org/2000/svg" width="20.631" height="16.631" viewBox="0 0 20.631 16.631">
                        <g transform="translate(0.116 0.016)"><g transform="translate(0.7 7.727)">
                            <path d="M19.7,10.3v7.608a.485.485,0,0,1-.435.466H1.135A.485.485,0,0,1,.7,17.908V10.3" transform="translate(-0.7 -10.3)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1.631"/>
                        </g>
                            <g transform="translate(6.31 0.8)"><line y2="11.877" transform="translate(3.959)" stroke-width="1.631" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" fill="none"/><path d="M16.718,10.5l-3.959,4.347L8.8,10.5" transform="translate(-8.8 -2.97)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1.631"/>
                            </g>
                        </g>
                    </svg>
                    {{ $t('downloads.download') }}
                </button>
            </div>
        </div>
        <additional-terms-modal ref="additionalTermsModal"
                                :content="content"
                                @additional-terms-modal-has-closed="$emit('additional-terms-modal-has-closed')">
        </additional-terms-modal>
    </div>
</template>

<script>
    import ApiRouter from "../Systems/ApiRouter";
    import Helper from "../Helper/Helper";
    import AdditionalTermsModal from "./AdditionalTermsModal";

    export default {
        name: "DownloadPanel",
        components: {AdditionalTermsModal},
        mixins: [ApiRouter, Helper],
        props: ['content'],
        data: function () {
            return {
                isAgree: false,
                isAdditionalTermsAgree: false,
                selectedSize: '',
                selectedText: false
            }
        },
        methods: {
            selectSize: function (size) {
                this.selectedSize = size;
            },
            selectText: function () {
                this.selectedText = ! this.selectedText;
            },
            download: function () {
                if (this.canDownload) {
                    window.axios.post(this.routes.downloadContents(this.$route.params.id), {
                        agree: this.isAgree,
                        size: this.selectedSize
                    })
                        .then((response) => {
                            this.sendDownloadResponse(response.data.download_url);
                        });

                    this.downloadText();
                }
            },
            downloadText: function () {
                if (this.isPc()) {
                    const blob = new Blob(
                        [this.content.relationships.details[this.$i18n.locale].content.replace(/(<br>|<br \/>)/gi, '')],
                        {"type":"text/plain"}
                        );
                    let link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    link.download = 'download.txt';
                    link.click();
                }
            },
            sendDownloadResponse: function (url) {
                const link = document.createElement('a');
                link.href = url;
                link.click();
            }
        },
        computed: {
            canDownload: function () {
                if (this.isLicenseType) {
                    return this.selectedSize !== '' && this.isAgree && this.isAdditionalTermsAgree;
                } else {
                    return this.selectedSize !== '' && this.isAgree || this.selectedText && this.isAgree
                }
            },
            isLicenseType: function () {
                if (this.content) {
                    return this.content.attributes.additional_restriction === 'searchConditions.additionalRestrictions.dazaifu' ||
                        this.content.attributes.additional_restriction === 'searchConditions.additionalRestrictions.isejingu' ||
                        this.content.attributes.additional_restriction === 'searchConditions.additionalRestrictions.kunozan' ||
                        this.content.attributes.additional_restriction === 'searchConditions.additionalRestrictions.murakami' ||
                        this.content.attributes.additional_restriction === 'searchConditions.additionalRestrictions.murakamiForChosenji' ||
                        this.content.attributes.additional_restriction === 'searchConditions.additionalRestrictions.nachikumanotaisha' ||
                        this.content.attributes.additional_restriction === 'searchConditions.additionalRestrictions.nageiredou' ||
                        this.content.attributes.additional_restriction === 'searchConditions.additionalRestrictions.sazaedo' ||
                        this.content.attributes.additional_restriction === 'searchConditions.additionalRestrictions.takeoonsen' ||
                        this.content.attributes.additional_restriction === 'searchConditions.additionalRestrictions.yashimaji' ||
                        this.content.attributes.additional_restriction === 'searchConditions.additionalRestrictions.about' ||
                        this.content.attributes.additional_restriction === 'searchConditions.additionalRestrictions.ama' ||
                        this.content.attributes.additional_restriction === 'searchConditions.additionalRestrictions.asukadera' ||
                        this.content.attributes.additional_restriction === 'searchConditions.additionalRestrictions.futagoji' ||
                        this.content.attributes.additional_restriction === 'searchConditions.additionalRestrictions.gyodaCityLocalMuseum' ||
                        this.content.attributes.additional_restriction === 'searchConditions.additionalRestrictions.hakoneSekisho' ||
                        this.content.attributes.additional_restriction === 'searchConditions.additionalRestrictions.jionji' ||
                        this.content.attributes.additional_restriction === 'searchConditions.additionalRestrictions.kamakurabungakukan' ||
                        this.content.attributes.additional_restriction === 'searchConditions.additionalRestrictions.kashiharajingu' ||
                        this.content.attributes.additional_restriction === 'searchConditions.additionalRestrictions.kenchoji' ||
                        this.content.attributes.additional_restriction === 'searchConditions.additionalRestrictions.kishutoshogu' ||
                        this.content.attributes.additional_restriction === 'searchConditions.additionalRestrictions.oosakihachimangu' ||
                        this.content.attributes.additional_restriction === 'searchConditions.additionalRestrictions.sendaitoshogu' ||
                        this.content.attributes.additional_restriction === 'searchConditions.additionalRestrictions.shurijoato' ||
                        this.content.attributes.additional_restriction === 'searchConditions.additionalRestrictions.konojinjya' ||
                        this.content.attributes.additional_restriction === 'searchConditions.additionalRestrictions.wakayamajo' ||
                        this.content.attributes.additional_restriction === 'searchConditions.additionalRestrictions.nihonkoku' ||
                        this.content.attributes.additional_restriction === 'searchConditions.additionalRestrictions.wakanoura'||
                        this.content.attributes.additional_restriction === 'searchConditions.additionalRestrictions.izakamakura' ||
                        this.content.attributes.additional_restriction === 'searchConditions.additionalRestrictions.masamune' ||
                        this.content.attributes.additional_restriction === 'searchConditions.additionalRestrictions.kongoji' ||
                        this.content.attributes.additional_restriction === 'searchConditions.additionalRestrictions.muroji' ||
                        this.content.attributes.additional_restriction === 'searchConditions.additionalRestrictions.nyonnkoya' ||
                        this.content.attributes.additional_restriction === 'searchConditions.additionalRestrictions.tamahagane' ||
                        this.content.attributes.additional_restriction === 'searchConditions.additionalRestrictions.itamiCityMuseum' ||
                        this.content.attributes.additional_restriction === 'searchConditions.additionalRestrictions.kikumasamune' ||
                        this.content.attributes.additional_restriction === 'searchConditions.additionalRestrictions.itami'
                }
                return false;
            }
        },
        watch: {
            content: function () {
                this.isAgree = false;
                this.isAdditionalTermsAgree = false;
                this.selectedSize = '';
                this.selectedText = false;
            }
        }
    }
</script>

<style src="./DownloadPanel.scss" lang="scss" scoped />
